/* NOTE: 
.chat-conv-window p {} styles all <p> elements inside of .chat-conv-window, including the nested div elements.
p.chat-conv-window {} styles only <p> elements that directly have the .chat-conv-window class.
Usually we need the nested version! 
*/

/* fff2fc */
/* ededed */
:root {
    --global-width: 100%;
    --navbar-textcolor: #141414;
    --user_message_background_color: #5c5c5c;
    --bot_message_background_color: #f05b7e;
    --continue_message_background_color: #ffffff;
}

/* @import "./variables.css"; MUST import variables.css first */

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* * is the universal selector in CSS.
It targets and applies styles to every HTML element on the page.*/

* {
    margin: 0;
    font-family: "Montserrat";
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
}

body {
    width: 100%;
    margin: 0;
    overscroll-behavior: auto;
    color: #355057;
}

h1 {
    font-size: 2.5rem;
}

p {
    line-height: 1.75;
}

/* ################################ */
/* ################################ */
/* ################################ */

/* .landing-container {
    height: 82vh;
    margin: 10px auto;
    padding: 5px;
    max-width: var(--global-width);
    text-align: center;
    background: #f0f2f5;
    min-height: 80vh;
    padding: 20px;
}

.landing-container__header {
    margin-top: 50px;
}

.landing-container__header h1 {
    font-size: 36px;
    color: #333;
}

.landing-container__landing-content {
    margin-top: 50px;
} */

/* ################################ */
/* ################################ */
/* ################################ */

.live-chatbot-container-panel {
    width: 650px;
    height: 500px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.live-chatbot-container {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.live-chatbot-container--contained {
    width: 100%;
    height: 100%;
}

.live-chat-conv-container {
    flex: 1;
    overflow-y: auto;
}

.live-conv-window {
    margin: 5px;
    padding: 10px;
    clear: both;
}

.live-conv-window p {
    margin: 5px 0; /* Set top and bottom margins to 5px, left and right to 0 */
    white-space: pre-wrap; /* Keep newlines and spaces */
}

.live-conv-window__text {
    max-width: 80%; /* Set max-width to 70% */
    display: inline-block; /* Makes the div only as wide as its contents */
    padding: 5px 15px 5px 15px; /* Reduced bottom padding */
    line-height: 1.5; /* Increased line height */
    border-radius: 10px;
}

.live-conv-window.user .live-conv-window__text {
    padding: 5px 15px 5px 15px;
    background-color: var(--user_message_background_color);
    float: right; /* Right-align for the user */
    color: rgb(239, 239, 239); /* Text color set to white */
}

.live-conv-window.bot .live-conv-window__text {
    padding: 5px 15px 5px 15px;
    background-color: var(--bot_message_background_color);
    color: rgb(239, 239, 239);
    float: left; /* Left-align for the bot */
}

.live-conv-window.continue .live-conv-window__text {
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */
    background-color: var(--continue_message_background_color);
}

.live-preset-container {
    margin-left: 3px;
    text-align: left;
}

.live-preset-container__button {
    white-space: nowrap;
    display: inline-block; /* Makes the button only as wide as its contents */
    border-radius: 15px;
    border: none;
    margin: 5px;
    max-width: 95%;
    overflow: hidden;
    background-color: var(--bot_message_background_color);
}

/* this is the entire div container area at the bottom where the user enters his question */
/* it includes the text area plus the left & right buttons */
.live-user-input-container {
    display: flex;
    margin-top: 3px;
    padding: 3px;
}

.live-user-input-container__textarea {
    width: 100%;
    margin: 0 3px;
}

/* ################################ */
/* ################################ */
/* ################################ */

.chatbot-panel {
    height: 82vh;
    margin: 10px auto;
    padding: 5px;
    max-width: 800px;
    padding: 5px 5px;
    margin: 10px auto;
    margin-top: 100px;
    font-weight: 500;
    /* text-align: center; */
}

.chatbot-panel h1 {
    font-size: 25px;
    color: #3493eb;
    margin-bottom: 10px;
    padding-bottom: 10px;
    /* border-bottom: 1px solid #000000; */
}

.chatbot-panel a {
    text-decoration: none;
}

/* ################################ */
/* ################################ */
/* ################################ */

.my-chatbots {
    height: 82vh;
    margin: 10px auto;
    padding: 5px;
    max-width: 50%;
    padding: 5px 5px;
    margin: 10px auto;
    text-align: center;
}

.my-chatbots h1 {
    font-size: 30px;
    color: #000000;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #000000;
}

.my-chatbots a {
    text-decoration: none;
}

.chatbot-list-settings {
    max-width: 50%;
    margin: 20px auto;
    padding: 10px;
}

.chatbot-list-settings:hover {
    box-shadow: 5px 3px 15px rgba(0, 0, 0, 0.2);
}

.chatbot-list-settings h2 {
    font-size: 20px;
    color: #3493eb;
}

/* ################################ */
/* ################################ */
/* ################################ */

.navbar {
    max-width: var(--global-width);
    margin: 0 auto;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #f2f2f2;
}

.navbar a {
    font-size: 1.2rem;
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
    color: var(--navbar-textcolor);
}

.navbar a:hover {
    color: #3493eb;
}

.navbar__links {
    display: flex;
}

/* ################################ */
/* ################################ */
/* ################################ */

.footer {
    max-width: 100%;
    /* margin: 0 auto; */
    /* padding: 5px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 3px solid #f2f2f2;
}

.footer a {
    font-size: 1.2rem;
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
    color: var(--textcolor);
}

.footer a:hover {
    color: #3493eb;
}

.footer__left-links {
    display: flex;
}

/* ################################ */
/* ################################ */
/* ################################ */

.sources-file-upload-container {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease-in-out;
}

.sources-file-upload-container.drag-active {
    border-color: #2196f3;
}

.sources-file-upload-container input[type="file"] {
    display: none;
}

.custom-shape-divider-bottom-1699353323 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1699353323 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 62px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1699353323 .shape-fill {
    fill: #ffffff;
}

.flex {
    display: flex;
    align-items: center;
}

.flex.fill {
    height: 100%;
}

.flex.center {
    justify-content: center;
}
